import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const SiyahCelikHasirPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Siyah Çelik Hasır Tel İmalatı - Erez Kaynak'
        description='Firmamız siyah ham çelik tel malzeme kullanarak punta kaynaklı çelik hasır tel imalatı yapmaktadır. Çelik hasır teller ham malzeme olduğu için sonradan fırın boya yada daldırma boya ile boyanabilir. Tel kalınlığı 2mm den 8mm tel kalınlığına kadar çelik hasır tel imalatını yapabiliyoruz.'
        keywords='çelik hasır,çelik hasır tel,hasır tel,çelik tel hasır,çelik kafes tel,çelik tel,çelik hasır tel imalatı,çelik hasır tel fiyatları,hasır tel fiyatları,çelik kafes tel fiyatları,çelik çesan,çelik kafes hasır,çelik fens teli,çelik hasır tel istanbul,çelik hasır tel kullanım alanları'
        url='siyah-celik-hasir'
        imgurl={getImageByName('siyahcelikhasir').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('siyahcelikhasir').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Siyah Çelik Hasır' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Normal demir çelik hasırlar <strong>boyasız düz çelik demir</strong> malzemeden üretilmektedir.
              </p>
              <p>
                Normal demir çelik hasırlar ham malzeme olduğu için sonradan <strong>fırın boya</strong> ya da{' '}
                <strong>daldırma boya</strong> ile boyanabilir.
              </p>
              <p>
                Siyah çelik hasırlar istenildiği takdirde <strong>krom</strong> kaplanabilir.
              </p>
              <p>
                Tel kalınlığı 2mm'den 8mm tel kalınlığına kadar <strong>çelik hasır</strong> imalatı yapabilmekteyiz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>çelik hasır</strong> imalatı
                yapabilmekteyiz. Örnek olarak ; 15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz ölçüde{' '}
                <strong>çelik hasır</strong> yapabilmekteyiz. Özel göz aralıklarında (örn; 26,3x37,5mm vb.) yeni kalıp yaparak hızlı bir
                şekilde müşterilerimizin ihtiyacı olan <strong>çelik hasırı</strong> uygun bir maaliyet ile üretiyoruz.
              </p>
              <p>
                <strong>Ebat</strong> olarak standart kalıplarımızda en büyük ölçü 2200x3000mm'dir.
              </p>
              <p>
                Standart kalıplarımız dışında istenilen boy ve ebatta <strong>siyah çelik hasır</strong> imalatı yapılmaktadır.
              </p>
              <p>
                Örnek olarak 50cmx175cm, 155cmx275cm ölçülerinde <strong>siyah çelik hasır</strong> imalatı yapabilmekteyiz.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center' marginTop={5}>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('siyahcelikhasir')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />
      <Box>
        <HasirTelKullanim title='Normal Demir Çelik Hasır Kullanım Alanları' />
      </Box>
      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default SiyahCelikHasirPage
